.card {
  width: 14rem !important;
  height: 16rem !important;
  border-radius: 1rem !important;
  // border: solid!important;
  // border-color: var(--primary-theme-color)!important;
  background-color: var(--primary-text-color) !important;

  &:hover {
    filter: brightness(1);
    transform: translateY(-5px);
    box-shadow: 0.1rem 1rem 2rem 0.5rem var(--light-primary-shadow-color);
  }

  &__news {
    width: auto !important;
    height: 25rem !important;
    border-radius: 1rem !important;
    &:hover {
      transform: translateY(0px);
      filter: brightness(1);
      box-shadow: 0.1rem 0.5rem 1rem 0.5rem var(--light-primary-shadow-color);
    }
    @include desktop {
      width: auto !important;
      height: auto !important;
    }
  }

  &__person {
    width: 20rem !important;
    height: 25rem !important;
    border-radius: 1rem !important;
    &:hover {
      transform: translateY(0px);
      filter: brightness(1);
      box-shadow: 0.1rem 0.5rem 1rem 0.5rem var(--light-primary-shadow-color);
    }
    @include desktop {
      width: 19rem !important;
      height: 32rem !important;
    }
  }

  &__course {
    width: 20rem !important;
    height: 25rem !important;
    border-radius: 1rem !important;
    &:hover {
      transform: translateY(0px);
      filter: brightness(1);
      box-shadow: 0.1rem 0.5rem 1rem 0.5rem var(--light-primary-shadow-color);
    }
    @include desktop {
      width: 19rem !important;
      height: 30rem !important;
    }
    overflow: hidden;
  }

  &__small {
    width: 7rem !important;
    height: 7rem !important;
    padding: 1rem !important;

    border-radius: 1rem !important;
    &:hover {
      transform: translateY(0px);
      filter: brightness(1);
      box-shadow: 0rem 0rem 0.2rem 0rem var(--light-primary-shadow-color);
    }
    @include desktop {
      width: 7rem !important;
      height: 7rem !important;
      padding: 1rem !important;
    }
  }
}

.card-body {
  padding: 2rem !important;
  @include desktop {
    padding: 2rem !important;
  }
}

.card-img {
  padding: 1rem !important;
}

.card-header {
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
  @include desktop {
    height: 12rem;
  }
}

.card-course-header {
  background-color: var(--secondary-highlight-color) !important;
  height: 6rem;
  padding: 1rem;
}

.card-footer {
  background-color: var(--secondary-highlight-color) !important;
  height: 5rem;
  @include desktop {
    height: 5rem;
  }
}

.card-timeline {
  padding: 1rem !important;

  &:hover {
    filter: brightness(1);
    transform: translateY(-5px);
    box-shadow: 0.1rem 0.5rem 1rem 0.5rem var(--light-primary-shadow-color);
  }

  --active {
    box-shadow: 0.1rem 0.5rem 1rem 0.5rem var(--light-primary-shadow-color);
  }
}
